<template>
  <div class="card-with-ribbon">
    <div class="corner-ribbon top-right" v-if="text">
      <slot name="icon">
        <inside-icon class="inside-icon" :size="36" />
      </slot>
      <div class="label">{{ text }}</div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import InsideIcon from 'vue-material-design-icons/Undo';

export default {
  props: {
    text: String,
  },
  name: 'CardWithRibbon',
  components: {InsideIcon},
};
</script>

<style lang="scss" scoped>
.card-with-ribbon {
  overflow: hidden;
  position: relative;
  min-height: 250px;
  min-width: 200px;
  background-color: $background-alternate-color;
  max-width: fit-content;
  cursor: pointer;

  .corner-ribbon {
    background: $power-red;
    color: rgba($off-white, 0.8);
    width: 250px;
    height: 120px;
    line-height: 32px;
    text-align: center;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: $box-shadow-light;
    .label {
      padding-bottom: 5px;
    }
    .inside-icon {
      transform: scaleX(-1);
      margin-bottom: -4px;
    }
  }
  .top-right {
    right: -105px;
    top: -40px;
    position: absolute;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  img {
    display: block;
    min-height: 250px;
    margin: auto;
  }
}
</style>
