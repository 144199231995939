<template>
  <stand-out-block class="section">
    <h5>Search burial records in {{ cemeteryName }}</h5>
    <div class="form">
      <input placeholder="Last name" v-model="lastName" @keyup.enter="go" class="input" />
      <input placeholder="First name" v-model="firstName" @keyup.enter="go" class="input" />
      <mcr-button @click="go">Search</mcr-button>
    </div>
  </stand-out-block>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';

export default {
  props: {
    cemeteryName: String,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
    };
  },
  methods: {
    go() {
      this.$emit('submit', {firstName: this.firstName, lastName: this.lastName});
    },
  },
  components: {McrButton, StandOutBlock},
  name: 'SectionSearchRecords',
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  align-items: stretch;
  margin-top: 20px;

  > *:not(:last-child) {
    flex: 1;
    margin-right: 20px;
  }
}
@media only screen and (max-width: $breakpoint-desktop) {
  .form {
    flex-direction: column;
    > *:not(:last-child) {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}
</style>
