<template>
  <div class="metadata-table">
    <div class="text-lg bold title" v-if="$slots.title">
      <slot name="title"></slot>
    </div>
    <div class="table">
      <slot name="rows">
        <div v-for="dataRow in rows" :key="dataRow.key || dataRow.code" class="table-row text-md">
          <div class="cell cell-name">
            <slot name="cell-name" :data="dataRow"></slot>
          </div>
          <div class="cell text-capitalize" :class="{concealed: isClassified}">
            <slot name="cell-value" :data="dataRow"></slot>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rows: Array,
    isClassified: {type: Boolean, default: false},
  },
  name: 'PersonsTable',
};
</script>

<style lang="scss" scoped>
@import './styles/metadata-table';
</style>
