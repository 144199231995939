<template>
  <div class="details-item table-row text-md">
    <mcr-wiki-glossary-link v-if="wikiId" class="cell cell-name" :naturalId="wikiId" :is-help-text="true">
      {{ label }}
    </mcr-wiki-glossary-link>
    <div class="cell cell-name" v-else>{{ label }}</div>
    <div class="cell">
      <slot><div class="unknown-value">Unknown</div></slot>
    </div>
  </div>
</template>

<script>
import mcrWikiGlossaryLink from '@common/elements/glossary/mcrWikiGlossaryLink';

export default {
  props: {
    label: String,
    wikiId: {type: String, optional: true},
  },
  components: {mcrWikiGlossaryLink},
};
</script>

<style lang="scss" scoped></style>
