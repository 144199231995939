<template>
  <div class="stand-out-block">
    <div class="heading">
      <slot name="heading"></slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.stand-out-block {
  border-radius: 4px;
  background-color: $background-light;

  .heading {
    background: $neutral-100;
    border-bottom: 1px solid $neutral-200;
    &::v-deep .title {
      color: $neutral-600;
    }
  }
  .heading,
  .content {
    padding: 12px 16px;
  }
}
</style>
