export default {
  address: "{{ address|default_if_none:'' }}",
  associations: [],
  burial_records_count: '{{ burial_records_count }}',
  desc: "{{ desc|default_if_none:'' }}",
  location: {
    lat: '{{ location.lat }}',
    lng: '{{ location.lng }}',
  },
  meta_image_url: '{{ meta_image_url }}',
  name: '{{ name }}',
  object_id: '{{ object_id }}',
  place_details: [
    {
      id: '{{ place.id }}',
      level_name: '{{ place.level_name }}',
      pinyin: '{{ place.pinyin }}',
    },
  ],
  place_full_name: '{{ place_full_name }}',
  cemetery_source: {
    ancestral_places: [],
    clans: [],
    cover: {
      id: "{{ cemetery_source.cover.id|default_if_none:'' }}",
      priority: "{{ cemetery_source.cover.priority|default_if_none:'' }}",
      url: "{{ cemetery_source.cover.url|default_if_none:'' }}",
    },
    providers: [
      {
        url: "{{ cemetery_source.providers.0.url|default_if_none:'' }}",
        name: "{{ cemetery_source.providers.0.name|default_if_none:'' }}",
      },
    ],
    id: '{{ cemetery_source.id }}',
    images_count: '{{ cemetery_source.images_count }}',
    publication_year: '{{ cemetery_source.publication_year }}',
    time_period_end: '{{ cemetery_source.time_period_end }}',
    time_period_start: '{{ cemetery_source.time_period_start }}',
    title_ch: '{{ cemetery_source.title_ch }}',
    title_en: '{{ cemetery_source.title_en }}',
    type: '{{ cemetery_source.type }}',
    type_display: '{{ cemetery_source.type_display }}',
  },
  related_sources: [
    {
      ancestral_places: [],
      clans: [],
      cover: {
        id: "{{ related_source.cover.id|default_if_none:'' }}",
        priority: "{{ related_source.cover.priority|default_if_none:'' }}",
        url: "{{ related_source.cover.url|default_if_none:'' }}",
      },
      providers: [
        {
          url: "{{ related_source.providers.0.url|default_if_none:'' }}",
          name: "{{ related_source.providers.0.name|default_if_none:'' }}",
        },
      ],
      id: '{{ related_source.id }}',
      images_count: '{{ related_source.images_count }}',
      publication_year: '{{ related_source.publication_year }}',
      time_period_end: '{{ related_source.time_period_end }}',
      time_period_start: '{{ related_source.time_period_start }}',
      title_ch: '{{ related_source.title_ch }}',
      title_en: '{{ related_source.title_en }}',
      type: '{{ related_source.type }}',
      type_display: '{{ related_source.type_display }}',
    },
  ],
  website: "{{ website|default_if_none:'' }}",
};
